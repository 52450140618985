import React from 'react';
import ReactDOM from 'react-dom';
import 'greeva/css/vendor.css';
import 'index.scss';
import AppProviders from 'components/AppProviders'
import App from 'components/App';
import Config from 'config';
import * as serviceWorker from 'serviceWorker';

const app = <AppProviders>
                <App />
            </AppProviders>

if (Config.strictMode) {
    ReactDOM.render(
        <React.StrictMode>
            {app}
        </React.StrictMode>,
        document.getElementById('root')
    );
} else {
    ReactDOM.render(
        app,
        document.getElementById('root')
    );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
