import React from 'react';
import {mergeClassName} from 'services/utils';
import 'components/shared/Busy.scss';

const Busy = props => {
    return  <div className={mergeClassName('busy-marker', !props.value && 'd-none', props.className)}>
                <div className="spinner-grow spinner-grow-sm text-secondary" role="status"></div>
            </div>
}

export default React.memo(Busy);
