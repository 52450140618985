import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
import {initReactI18next} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n.on('languageChanged', lng => {
    localStorage.setItem('lng', lng);
});

i18n
  // load translation using xhr -> see /public/locales
  // learn more: https://github.com/i18next/i18next-xhr-backend
  .use(Backend)
  // detect default language
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: 'en',
    debug: false,
    interpolation: {
        escapeValue: false // not needed for react as it escapes by default
    },
    detection: {
        order: ['localStorage'],
        lookupLocalStorage: 'lng',
    },
});

export default i18n;