import React from 'react';
import ReactNotification from 'react-notifications-component';
import { useAuth } from 'context/auth';
import 'react-notifications-component/dist/theme.css';
import 'components/App.scss';

const PrivateApp = React.lazy(() => import('components/PrivateApp'))
const PublicApp = React.lazy(() => import('components/PublicApp'))

const App = () => {
    const auth = useAuth();
    return  <>
                <ReactNotification />
                <div id="wrapper">
                    {auth.hasSession() ? <PrivateApp /> : <PublicApp />}
                </div>
            </>
}

export default App;
