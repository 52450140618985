export const SS_ACC_KEY = '_kts_acc';

let cachedPermissions;

function read() {
    const permissions = window.localStorage.getItem(SS_ACC_KEY);
    if (permissions) {
        return permissions.split(',');
    }
    return null;
}

export function get() {
    if (!cachedPermissions) {
        cachedPermissions = read();
    }
    return cachedPermissions;
}

export function write(permissions) {
    window.localStorage.setItem(SS_ACC_KEY, permissions);
}

export function clear() {
    window.localStorage.removeItem(SS_ACC_KEY);
    cachedPermissions = null;
}

export function can(action) {
    return get().includes(action);
}

export function cannot(action) {
    return !can(action);
}