import React from 'react';
import {Link} from 'react-router-dom';
import {store} from 'react-notifications-component';
import Swal from 'sweetalert2';
import querystring from 'query-string';
import _ from 'lodash';

export function trim(string, char) {
    while (string.startsWith(char)) {
        string = string.substr(1);
    }
    while (string.endsWith(char)) {
        string = string.substr(0, string.length - 1);
    }
    return string;
}

export function renderLink(title, url) {
    return <Link to={url}>{title}</Link>;
}

export function renderIcon(icon, title) {
    return <i className={icon} title={title}></i>;
}

export function renderActive(active, titles) {
    return renderIcon(Number(active) ? 'dripicons-checkmark' : 'dripicons-lock', titles ? titles[active] : null);
}

export function renderBadge(text, type, attrs = {}) {
    return <small key={text} className={`badge badge-${type} ${attrs.cssClass}`} {..._.omit(attrs, 'key', 'cssClass')}>{text}</small>
}

export function isObject(obj) {
    return typeof obj === 'object' && obj !== null;
}

export function valueIsEmpty(value) {
    if (typeof value === 'string') {
        value = value.trim();
    }
    return value !== 0 && !value;
}

export function mapNonEmpty(obj) {
    return Object.entries(obj).reduce((a,[k,v]) => (!valueIsEmpty(v) ? (a[k]=v, a) : a), {});
}

export function emptyStringToNull(value, originalValue) {
    if (typeof originalValue === 'string' && originalValue === '') {
      return null;
    }
    return value;
}

export function showNotification(type, message, options) {
    const defaultOptions = {
        title: '',
        insert: 'top',
        container: 'top-center',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
            duration: 6000,
            onScreen: true,
            pauseOnHover: true,
            showIcon: true,
        }
    }
    return store.addNotification({
        ...defaultOptions,
        ...options,
        type: type,
        message: message,
    });
}

export function removeNotification(id) {
    store.removeNotification(id);
}

export function mergeClassName(...args) {
    args = args.filter(value => !!value);
    return args.join(' ');
}

export function newObjectFromKeys(obj, defaultValue) {
    defaultValue = defaultValue !== undefined ? defaultValue : null;
    return Object.fromEntries(Object.keys(obj).map(key => [key, defaultValue]));
}

export function newObjectFromValues(arr, defaultValue) {
    defaultValue = defaultValue !== undefined ? defaultValue : null;
    return Object.fromEntries(arr.map(value => [value, defaultValue]));
}

export function translateObjectValues(obj, tr) {
    return Object.fromEntries(Object.entries(obj).map(([k, v]) => [k, tr(v)]));
}

export function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export function nullsToEmptyStrings(obj) {
    if (Array.isArray(obj)) {
        return obj.map(v => v === null ? '' : v);
    } else {
        return Object.fromEntries(Object.entries(obj).map(([k, v]) => [k, v === null ? '' : v]));
    }
}

export function showConfirm(text, options) {
    return Swal.fire({...{
        title: null,
        text: text,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#f34943',
        cancelButtonColor: '#6c757d',
        confirmButtonText: 'Yes',
        cancelButtonText: 'Cancel',
    }, ...options});
}

export function escapeRegEx(string) {
    return string.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

export function getOffsetTop(elem) {
    return window.pageYOffset + elem.getBoundingClientRect().top;
}

export function mergeUrlFilters(defaultFilters) {
    //read filters from url
    const urlParams = {};
    for (const [key, value] of Object.entries(querystring.parse(window.location.search))) {
        let match = key.match(/filters\[(.+)\]/);
        if (match && match[1]) {
            urlParams[match[1]] = value;
        }
    }
    // overwrite default values with values from url
    let filters = {};
    for (const [key, value] of Object.entries(defaultFilters)) {
        filters[key] = urlParams[key] || value;
    }
    return filters;
}

export function toSelectOptions(list, valueProp = 'id', labelProp = 'name') {
    return list.map(item => ({
        label: item[labelProp],
        value: item[valueProp],
    }));
}

export function isDev() {
    return process.env.REACT_APP_ENV === 'dev';
}

export function devlog(...args) {
    if (isDev()) {
        console.log(...args);
    }
}

export function devwrn(...args) {
    if (isDev()) {
        console.warn(...args);
    }
}

export function deverr(...args) {
    if (isDev()) {
        console.error(...args);
    }
}

export function responseCodeIs(response, code) {
    return response && response.data && Number(response.data.__code) === code;
}

export function responseStatusIs(response, status) {
    return response && response.status === status;
}

export const HttpStatus = {
    NotFound: 404,
    Forbidden: 403,
}