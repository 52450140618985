import React, {useCallback, useState, useEffect} from 'react';
import jwt from 'jsonwebtoken';
import {useTranslation} from "react-i18next";
import { showNotification } from 'services/utils';
import { clear as clearPermissions } from 'services/access';

// the jwt will be stored in the storage under this key
const SS_JWT_KEY = '_kts_jat';
// the public key will be stored in the storage under this key
const SS_PEM_KEY = '_kts_jak';

// default context data
const defaultData = {
    token: null,
    user: null,
    referer: null,
    expired: false,
};

const AuthContext = React.createContext();

const AuthProvider = props => {

    const {t} = useTranslation();

    // decodes a jwt and returns its payload
    const getPayload = useCallback(tkn => {
        // token is null or empty
        if (!tkn) {
            return;
        }
        // get public key from storage
        const key = window.localStorage.getItem(SS_PEM_KEY);
        // public key is null or empty
        if (!key) {
            return;
        }
        // try to decode the jwt
        try {
            // return the payload
            return jwt.verify(tkn, key);
        // jwt decoding failed
        } catch(ex) {
            // if reason is that jwt has expired
            if (ex.name === 'TokenExpiredError') {
                // we need to treat this in a particular way
                // therefore return FALSE so we can differentiate from NULL (all other reasons)
                return false;
            }
        }
    }, []);

    // overwrites the default data with custom values
    const mergeWithDefaultData = useCallback(values => Object.assign({}, defaultData, values), []);

    // overwrites the default data with token and user data
    const mergeAuthWithDefaultData = useCallback((tkn, payload) => mergeWithDefaultData({
        token: tkn,
        user: {
            id: payload.id,
            firstName: payload.firstName,
            lastName: payload.lastName,
            fullName: `${payload.firstName} ${payload.lastName}`,
            email: payload.email,
            roles: payload.roles,
        },
    }), [mergeWithDefaultData]);
    
    // returns the data that should populate the context on first render
    // if already authenticated it returns the token and user data
    // else it returns the default data
    const getDefaultData = useCallback(() => {
        // get token from storage
        const tkn = getSessionToken();
        // try to decode and get payload
        const payload = getPayload(tkn);
        // if token is valid
        if (payload) {
            // return the user data
            return mergeAuthWithDefaultData(tkn, payload);
        // if token cannot be decoded
        } else {
            // check if the reason is session having expired
            if (payload === false) {
                // return the default data but with a flag
                // to mark that we are dealing with an expired session
                return Object.assign({}, defaultData, {expired: true});
            }
            // for any other reason just return the default data
            return defaultData;
        }
    }, [getPayload, mergeAuthWithDefaultData]);

    // removes token and public key from storage
    const cleanup = useCallback(() => {
        window.localStorage.removeItem(SS_JWT_KEY);
        window.localStorage.removeItem(SS_PEM_KEY);
        clearPermissions();
    }, []);

    // determine the default context data
    const defData = getDefaultData();
    // if token has expired
    if (defData.expired) {
        // clear the storage
        cleanup();
    }

    // populate the state with the default data
    const [data, setData] = useState(defData);

    // saves the token into the storage
    // updates the context state with the user data
    // the change in state will trigger a render in App
    // switching from PublicApp to PrivateApp
    const login = useCallback(tkn => {
        // try to decode the token
        // ideally this should always succeed because this is a fresh token
        const payload = getPayload(tkn);
        // nevertheless we check if the token is valid
        if (payload) {
            // save the token into the storage
            window.localStorage.setItem(SS_JWT_KEY, tkn);
            // update the state
            // this also triggers a page change because the auth context changes
            setData(mergeAuthWithDefaultData(tkn, payload));
            // notify success
            return true;
        }
        // for some unexpected reason the token is invalid
        return false;
    }, [getPayload, mergeAuthWithDefaultData]);

    // clears the storage
    // clears the context state
    // the change in state will trigger a render in App
    // switching from PrivateApp to PublicApp
    const logout = useCallback(expired => {
        // clear the storage
        cleanup();
        // clear the context state but leave a flag
        // to mark whether we are dealing with an expired session or a manual logout
        setData(Object.assign({}, defaultData, {expired: expired || false}));
    }, [cleanup]);

    const hasSession = useCallback(() => !!data.user, [data.user]);

    // checks if the current token is still valid
    const checkSession = useCallback(() => {
        // read the token from storage
        const tkn = getSessionToken();
        // try to decode
        const payload = getPayload(tkn);
        // if decoding fails for whatever reason
        if (!payload) {
            // terminate the session
            logout(payload === false);
        }
    }, [getPayload, logout]);

    const isSelf = useCallback((userId) => data.user && userId === data.user.id, [data.user]);

    // updates the state with a new referer
    const setReferer = useCallback(rfr => setData(oldData => ({
        ...oldData,
        referer: rfr,
    })), []);

    // saves the public key into the storage
    const setKey = useCallback(key => {
        window.localStorage.setItem(SS_PEM_KEY, key);
    }, []);

    // called every time the 'expired' state var changes
    useEffect(() => {
        // we only care about those times when 'expired' is set to 'true'
        if (data.expired) {
            // let the user know why he has been kicked out
            showNotification('danger', t('session_expired'), {id: 'sessExpNotif'});
        }
    }, [t, data.expired]);

    return <AuthContext.Provider value={{data, hasSession, checkSession, isSelf, login, logout, setReferer, setKey}} {...props} />
}

// helper hook that makes context data available
const useAuth = () => React.useContext(AuthContext);

// reads token from storage
const getSessionToken = () => window.localStorage.getItem(SS_JWT_KEY);

export { AuthProvider, useAuth, getSessionToken }