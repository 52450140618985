export default {
    beSimpleUrl: process.env.REACT_APP_BASE_BE_URL,
    beUrl: process.env.REACT_APP_BE_URL,
    publicKeyUrl: process.env.REACT_APP_PUB_KEY_URL,
    envIsStage: (process.env.REACT_APP_ENV_IS_STAGE === 'true'),
    serverErrCodeInvalidCredentials: 4000,
    serverErrCodeJwtExpired: 4003,
    serverErrCodeDeleteRestricted: 23000,
    strictMode: false,
    languages: {
        "en": "English",
        "ro": "Română",
    },
};