import React, {Suspense} from 'react';
import { I18nextProvider } from 'react-i18next';
import i18n from 'services/i18n';
import { BrowserRouter as Router } from 'react-router-dom';
import {AuthProvider} from 'context/auth';
import Busy from 'components/shared/Busy';

const AppProviders = props => {
    return  <Suspense fallback={loader}>
                <I18nextProvider i18n={i18n}>
                    <Router>
                        <AuthProvider>{props.children}</AuthProvider>
                    </Router>
                </I18nextProvider>
            </Suspense>
}

const loader = <Busy value={true} />

export default AppProviders;